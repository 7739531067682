import {mapActions, mapGetters} from "vuex";
import {permissionMixin} from "@/mixins/permissionMixins";

export default {
  mixins : [permissionMixin],
  methods : {
    ...mapActions([
      'getAuditorList',
      'deleteAuditor',
      'createAuditor',
      'updateAuditor',
      'deleteAuditorCertificate',
      'getAuditTeamLeaders',
      'getAuditorDepartments',
      'getAuditorPositions',
      'getUserForAuditor'
    ]),
    async getAllLeadAndNonLeadAuditors() {
      await this.getAuditorList({
        'filter[is_lead]' : 0
      });
      await this.getAuditorList({
        'filter[is_lead]' : 1
      });
    },
    transformAuditorIds(param, formData) {
      for (const key in param) {
        if (key === 'members_ids' || key === 'lead_ids') {
          if (param[key].length === 0) {
            param[key] = '';
            formData.append(key, param[key]);
          } else {
            param[key].forEach((item, item_key ) => {
              formData.append(key + `[${item_key}]`, item);
            })
          }
        } else {
          formData.append(key, param[key]);
        }
      }
      return formData;
    }
  },
  computed : {
    ...mapGetters([
      'leadAuditors',
      'nonLeadAuditors',
      'audit_team_leaders',
      'auditor_departments',
      'auditor_positions',
    ]),
    leadAndNonLeadAuditors() {
      return [].concat(this.leadAuditors, this.nonLeadAuditors);
    }
  },
  async created() {
    await this.getAllLeadAndNonLeadAuditors();
    await this.getAuditorDepartments();
    await this.getAuditorPositions();
  }
}

<script>
import PopperModal from "@/components/elements/PopperModal.vue";
import {AlertService} from "@/services/AlertService";
import DateMixin from "@/mixins/DateMixin";
import AuditorMixin from "@/mixins/AuditorMixin";
import SearchDropdown from "@/components/modules/ia/SearchDropdown.vue";
import {mapActions, mapGetters} from "vuex";
import MultiSelectCustom from "@/components/MultiSelectCustom";
import OtherComp from "@/components/elements/OtherComp.vue";
import ImageComp from "@/components/elements/ImageComp.vue";

export default {
  name: "AddAuditor",
  components: {ImageComp, OtherComp, MultiSelectCustom, PopperModal, SearchDropdown},
  mixins:[DateMixin, AuditorMixin],
  data() {
    return {
      newAuditor : {
        first_name                      : '',
        last_name                       : '',
        middle_name                     : '',
        suffix                          : '',
        email                           : '',
        contact_number                  : '',
        auditor_department_id           : null,
        auditor_position_id             : null,
        management_office_id            : null,
        management_office               : null,
        is_internal_of_external_auditor : false,
        is_lead                         : false,
        lead_ids                        : [],
        members_ids                     : [],
        certificate_file                : null,
        certificate_issued_date         : null,
        certificate_expiration_date     : null
      },
      searchedUser : [],
      showCertificateField : false,
    }
  },
  methods: {
    ...mapActions(['getAllUsers', 'defineSelectedUser', 'getMngmtOfficeKeys']),
    async cancelAdd() {
      const response= await AlertService.cancelAlert();
      if(response){
        $('#AddNewAuditorModal').modal('hide');
        this.resetNewAuditor();
      }
    },
    resetNewAuditor() {
      this.newAuditor = {
        first_name                      : '',
        last_name                       : '',
        middle_name                     : '',
        suffix                          : '',
        email                           : '',
        contact_number                  : '',
        auditor_department_id           : null,
        auditor_position_id             : null,
        management_office_id            : null,
        management_office               : null,
        is_internal_of_external_auditor : false,
        is_lead                         : false,
        lead_ids                        : [],
        members_ids                     : [],
        certificate_file                : null,
        certificate_issued_date         : null,
        certificate_expiration_date     : null
      }
    },
    async setSelectedItem(data) {
      const user = await this.getUserForAuditor(data.id);

      this.searchedUser = [];
      this.newAuditor.first_name = user.first_name;
      this.newAuditor.last_name = user.last_name;
      this.newAuditor.middle_name = user.middle_name;
      this.newAuditor.suffix = user.suffix ?? '';
      this.newAuditor.email = user.email;
      this.newAuditor.contact_number = user.contact_number ?? '';
      this.newAuditor.auditor_department_id = user.auditor_department_id ?? null;
      this.newAuditor.auditor_position_id = user.auditor_position_id ?? null;
      this.newAuditor.management_office_id = user.management_office_id;
      this.setAuditorManagementOffice(user.management_office_id);
      this.newAuditor.is_internal_of_external_auditor = user.is_internal_of_external_auditor ?? false;
      this.newAuditor.is_lead = user.is_lead ?? false;
      this.newAuditor.lead_ids = user.lead_ids ?? [];
      this.newAuditor.members_ids = user.members_ids ?? [];
      this.newAuditor.certificate_file = user.certificate_file ?? null;
      this.newAuditor.certificate_issued_date = user.certificate_issued_date ?? null;
      this.newAuditor.certificate_expiration_date = user.certificate_expiration_date ?? null;
      this.$refs.auditor_certificate.value = null;
    },
    setAuditorManagementOffice(managementOfficeId) {
      let managementOffice = this.mngmntOfficeKeys.filter((managementOffice) => {
        return managementOffice.id === managementOfficeId;
      });
      managementOffice = managementOffice.length === 0 ? null : managementOffice[0];
      this.newAuditor.management_office = managementOffice;
    },
    async searchUserOrAuditor(value) {
      await this.getAllUsers({
        search : value,
        for_auditor_module : true
      });
      this.searchedUser = [];
      this.searchedUser.push(...this.allUsers);
    },
    setAuditorCertificate() {
      if (this.$refs.auditor_certificate.files[0]) {
        this.showCertificateField = true;
        this.newAuditor.certificate_file = this.$refs.auditor_certificate.files[0];
      } else {
        this.newAuditor.certificate_file = null;
        this.showCertificateField = false;
      }
    },

    async createNewAuditor() {
      let param = Object.assign({}, this.newAuditor);
      param.is_lead = param.is_lead === true ? 1 : 0;
      param.is_internal_of_external_auditor = param.is_internal_of_external_auditor === true ? 1 : 0;

      if (this.showCertificateField === false) {
        delete param.certificate_file;
        delete param.certificate_expiration_date;
        delete param.certificate_issued_date;
      }


      let formData = new FormData();
      formData = this.transformAuditorIds(param, formData);
      const createResponse = await this.createAuditor(formData);
      if (createResponse.result === false) {
        return AlertService.errorAlert(createResponse.message, 'ADDING OF AUDITOR');
      }

      $('body').removeClass('modal-open')
      $('.modal-backdrop').remove()
      $('.fade').remove()
      $('.show').remove()

      successAlert.fire({
        title: `ADDING OF AUDITOR SUCCESS`,
        showConfirmButton: true,
        allowOutsideClick:false,
        allowEnterKey:false,
        allowEscapeKey:false,
        timer: 1500,
        scrollbarPadding: false
      }).then(async (result)=>{
        if(result.isConfirmed){
          await this.$router.push({ name: 'AuditorProfile', params: { id: createResponse.data.id } }).catch(() => {
          })
        }
      })

      setTimeout(() => this.$router.push({name: 'AuditorProfile', params: {id: createResponse.data.id}}), 2000);

    },
    setLeadMembers(leadMemberIds) {
      this.newAuditor.lead_ids = leadMemberIds.selected
    },
    setNonLeadMembers(nonLeadMemberIds) {
      this.newAuditor.members_ids = nonLeadMemberIds.selected;
    },
    resetLeadsAndMembers() {
      this.newAuditor.lead_ids = [];
      this.newAuditor.members_ids = [];
    }
  },
  async created() {
    await this.getMngmtOfficeKeys();
  },
  computed: {
    ...mapGetters(['allUsers', 'mngmntOfficeKeys', 'leadAuditors', 'nonLeadAuditors']),
  },
}
</script>

<template>
  <div class="modal fade" data-backdrop="static" data-keyboard="false" id="AddNewAuditorModal" tabindex="-1">
    <div class="modal-dialog modal-lg modal-dialog-centered" role="document">
      <form class="modal-content" @submit.prevent="createNewAuditor">
        <div class="modal-header">
          <div class="modal-title w-100 text-center">
            <h5 class="font-weight-bold">Add New Auditor</h5>
          </div>
        </div>
        <div class="modal-body">
          <div class="form-group row no-gutters mb-1">
            <label for="first_name" class="font-weight-bold col-sm-3 col-form-label">Search User</label>
            <div class="col-sm-9">
              <search-dropdown
                :items="searchedUser"
                @setSelectedItem="setSelectedItem"
                @setSearchTerm="searchUserOrAuditor"
              />
            </div>
          </div>

          <!--          FOR FIRST NAME-->
          <div class="form-group row no-gutters mb-1">
            <label for="first_name" class="font-weight-bold col-sm-3 col-form-label">First Name</label>
            <div class="col-sm-9">
              <input type="text" id="first_name" class="form-control form-control-sm" required v-model="newAuditor.first_name">
            </div>
          </div>
          <!--          FOR LAST NAME -->
          <div class="form-group row no-gutters mb-1">
            <label for="last_name" class="font-weight-bold col-sm-3 col-form-label">Last Name</label>
            <div class="col-sm-9">
              <input type="text" id="last_name" class="form-control form-control-sm" required v-model="newAuditor.last_name">
            </div>
          </div>
          <!--          FOR MIDDLE NAME -->
          <div class="form-group row no-gutters mb-1">
            <label for="middle_name" class="font-weight-bold col-sm-3 col-form-label">Middle Name</label>
            <div class="col-sm-9">
              <input type="text" id="middle_name" class="form-control form-control-sm" v-model="newAuditor.middle_name">
            </div>
          </div>
          <!--          FOR SUFFIX -->
          <div class="form-group row no-gutters mb-1">
            <label for="extension" class="font-weight-bold col-sm-3 col-form-label">Suffix</label>
            <div class="col-sm-9">
              <input type="text" id="extension" class="form-control form-control-sm" placeholder="e.g. Jr., Sr." v-model="newAuditor.suffix">
            </div>
          </div>
          <!--          FOR CONTACT NUMBER -->
          <div class="form-group row no-gutters mb-1">
            <label for="email" class="font-weight-bold col-sm-3 col-form-label">Contact Number</label>
            <div class="col-sm-9">
              <input type="text" id="email" class="form-control form-control-sm" v-model="newAuditor.contact_number">
            </div>
          </div>
          <!--          FOR EMAIL -->
          <div class="form-group row no-gutters mb-1">
            <label for="email" class="font-weight-bold col-sm-3 col-form-label">Email</label>
            <div class="col-sm-9">
              <input type="email" id="email" class="form-control form-control-sm" v-model="newAuditor.email">
            </div>
          </div>
          <!--          FOR DEPARTMENT -->
          <div class="form-group row no-gutters mb-1">
            <label for="management_office_id" class="font-weight-bold col-sm-3 col-form-label">Department</label>
            <div class="col-sm-9">
              <select class="form-control form-control-sm text-uppercase" id="auditor_department_id" v-model="newAuditor.auditor_department_id">
                <option :value="null">—— Select Department ——</option>
                <option v-for="department in auditor_departments" :key="department.id" :value="department.id">
                  {{ department.name }}
                </option>
              </select>
            </div>
          </div>

          <!--          FOR POSITION -->
          <div class="form-group row no-gutters mb-1">
            <label for="management_office_id" class="font-weight-bold col-sm-3 col-form-label">Position</label>
            <div class="col-sm-9">
              <select class="form-control form-control-sm text-uppercase" id="auditor_position_id" v-model="newAuditor.auditor_position_id">
                <option :value="null">—— Select Position ——</option>
                <option v-for="department in auditor_positions" :key="department.id" :value="department.id">
                  {{ department.name }}
                </option>
              </select>
            </div>
          </div>
          <!--          FOR MANAGEMENT OFFICE -->
          <div class="form-group row no-gutters mb-1">
            <label for="management_office_id" class="font-weight-bold col-sm-3 col-form-label">Management Office</label>
            <div class="col-sm-9">
              <select class="form-control form-control-sm text-uppercase" id="management_office_id" v-model="newAuditor.management_office_id" @change="setAuditorManagementOffice(newAuditor.management_office_id)">
                <option :value="null">—— Select Management Office ——</option>
                <option v-for="mngmntOffice in mngmntOfficeKeys" :key="mngmntOffice.id" :value="mngmntOffice.id">
                  {{ mngmntOffice.name }}
                </option>
              </select>
            </div>
          </div>
          <!--          FOR INTERNAL OF EXTERNAL AUDITOR -->
          <div class="form-group row no-gutters mb-1">
            <label for="management_office_id" class="font-weight-bold col-sm-3 col-form-label">External Auditor?</label>
            <div class="col-sm-9">
              <select class="form-control form-control-sm text-uppercase" id="is_internal_of_external_auditor" v-model="newAuditor.is_internal_of_external_auditor">
                <option :value="true">Yes</option>
                <option :value="false">No</option>
              </select>
            </div>
          </div>
          <!--          FOR AN AUDITOR LEAD -->
<!--          <div class="form-group row no-gutters mb-1">-->
<!--            <label for="management_office_id" class="font-weight-bold col-sm-3 col-form-label">Is Auditor Lead?</label>-->
<!--            <div class="col-sm-9">-->
<!--              <select class="form-control form-control-sm text-uppercase" id="is_lead" v-model="newAuditor.is_lead" @change="resetLeadsAndMembers">-->
<!--                <option :value="true">Yes</option>-->
<!--                <option :value="false">No</option>-->
<!--              </select>-->
<!--            </div>-->
<!--          </div>-->

<!--          <div class="form-group row no-gutters mb-1">-->
<!--            <label for="lead_or_not_lead" class="font-weight-bold col-sm-3 col-form-label">-->
<!--              {{ newAuditor.is_lead === true ? 'Members' : 'Lead' }}-->
<!--            </label>-->
<!--            <multi-select-custom-->
<!--              v-if="newAuditor.is_lead === true"-->
<!--              id="members-data"-->
<!--              :items="nonLeadAuditors" :entity="'Members'"-->
<!--              @doneSelected="setNonLeadMembers"-->
<!--              :selected="newAuditor.members_ids">-->
<!--            </multi-select-custom>-->
<!--            <multi-select-custom-->
<!--              v-else-->
<!--              id="lead-data"-->
<!--              :items="leadAuditors" :entity="'Lead'"-->
<!--              @doneSelected="setLeadMembers"-->
<!--              :selected="newAuditor.lead_ids">-->
<!--            </multi-select-custom>-->
<!--          </div>-->
          <!--          FOR AUDITOR CERTIFICATE -->
          <div class="form-group row no-gutters mb-1">
            <label for="management_office_id" class="font-weight-bold col-sm-3 col-form-label">Auditor Certificate</label>
            <div>
              <input type="file" class="form-control-sm" ref="auditor_certificate" accept="application/pdf" @change="setAuditorCertificate">
            </div>
          </div>

          <template v-if="showCertificateField">
            <!--          FOR CERTIFICATE ISSUED DATE -->
            <div class="form-group row no-gutters mb-1">
              <label for="management_office_id" class="font-weight-bold col-sm-3 col-form-label">Certificate Issued Date</label>
              <div class="col-sm-9">
                <input type="date" :min="minimumDate"  :max="currentDate" class="form-control form-control-sm" v-model="newAuditor.certificate_issued_date">
              </div>
            </div>
            <!--          FOR CERTIFICATE EXPIRY DATE -->
            <div class="form-group row no-gutters mb-1">
              <label for="management_office_id" class="font-weight-bold col-sm-3 col-form-label">Certificate Expiry Date</label>
              <div class="col-sm-9">
                <input type="date" :min="minimumDate"  class="form-control form-control-sm" v-model="newAuditor.certificate_expiration_date">
              </div>
            </div>
          </template>

        </div>
        <div class="modal-footer">
          <button type="button" class="btn btn-sm btn-secondary" ref="NewUser_close" @click.prevent="cancelAdd">Close</button>
          <button type="submit" class="btn btn-sm btn-primary">Create Auditor</button>
        </div>
      </form>
    </div>
  </div>

</template>

<style scoped>
.dropdown-item {
  padding-left: 12px;
}
</style>

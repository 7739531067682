<script>
import AddAuditor from "@/components/modules/ia/auditor/AddAuditor.vue";
import {mapActions, mapGetters} from "vuex";
import {vueAppMixin} from "@/mixins/vueAppMixin";
import {PaginationMixin} from "@/mixins/PaginationMixin";
import AuditorMixin from "@/mixins/AuditorMixin";
import loading from "@/components/elements/AppLoading.vue";
import _ from 'lodash';
import {AlertService} from "@/services/AlertService";

export default {
  name: "AuditorList",
  components: {loading, AddAuditor},
  mixins: [PaginationMixin, vueAppMixin, AuditorMixin],
  data() {
    return {
      filters: {
        page: 1,
        count: 30,
        query : ''
      },
      loading : false,
    }
  },
  methods: {
    async deleteSelected(auditorId) {
      if(await AlertService.confirmDeleteAlert('Delete Auditor', 'Are you sure you want to delete this Auditor?', 'Delete')){
        swal.fire({
          title:'ENTER YOUR PASSWORD',
          input: 'password',
          inputAttributes: {
            autocapitalize: 'off'
          },
          confirmButtonText:'PROCEED DELETION',
          cancelButtonText: 'CANCEL',
          showCancelButton:true,
          allowOutsideClick:false,
          allowEscapeKey:false,
          allowEnterKey:false,
          confirmButtonColor:'#dc3545',
          showLoaderOnConfirm:true,
          preConfirm:async (inputValue)=> {
            let response = await this.deleteAuditor({auditor_id: auditorId, password: inputValue});
            if (typeof response === 'boolean' && response) {
              return response;
            } else {
              if (response.hasOwnProperty('message') && !response.hasOwnProperty('errors')) {
                swal.showValidationMessage(response.message);
              }
              else{
                swal.showValidationMessage(response.errors.password.join(','));
              }
            }
          }
        })
          .then(async (result)=>{
            if(result.isConfirmed){
              await this.successAlert('AUDITOR DELETED SUCCESSFULLY','DELETE AUDITOR');
              await this.filterAuditorList();
            }
          });
      }

    },
    async showAuditor (auditorId) {
      await this.$router.push(
        {
          name: 'AuditorProfile',
          params: {
            id: auditorId,
          },
        },

      ).catch(() => {})
    },
    async showAuditorNewTab (auditorId) {
      const route= this.$router.resolve(
        {
          name: 'AuditorProfile',
          params: {
            id: auditorId,
          },
        },
      )
      window.open(route.href,'_blank')
    },
    async setPage(page) {
      this.pagination.currentPage = page;
      this.filters.page = page
      await this.filterAuditorList();
    },
    async filterAuditorList() {
      this.loading = true;
      await this.getAuditorList(this.filters);
      this.loading = false;
    },
  },
  async created() {
    this.searchAuditor = _.debounce(this.filterAuditorList, 750);
    await this.filterAuditorList();
  },
  watch: {
    async 'filters.count'(count) {
      this.filters.count = count
      this.filters.page = 1
      await this.filterAuditorList();
    },
    async 'filters.query'(query){
      this.filters.query = query;
      this.searchAuditor();
    }
  },
  computed : {
    ...mapGetters([
      'auditorList'
    ]),
  }
}
</script>

<template>
  <div class="container-fluid px-4">
    <div class="row">
      <div class="col-12 pl-0">
        <div>
          <div class="row no-gutters justify-content-between mb-1">
            <div class="col-sm-auto flex-grow-0">
              <h3 class="mb-0 text-left">List of Auditors</h3>
            </div>
            <div class="col-sm-auto" v-if="isAllowed('audit-team.auditors.create')">
              <button class="e-btn e-btn-green force-white-all" data-toggle="modal" data-target="#AddNewAuditorModal">
                <font-awesome-icon icon="plus-square" style="width: 25px"/>
                <small class="font-weight-bold px-2">
                  NEW AUDITOR
                </small>
              </button>
            </div>
          </div>

          <div class="row no-gutters align-items-center justify-content-between">
            <div class="col-sm-auto pb-2 mr-2">
              <nav aria-label="...">
                <ul class="pagination text-smallest mb-0 pb-0">
                  <li class="page-item" :class="{ 'disabled': pagination.currentPage === 1}">
                    <button class="page-link" @click="setPage(1)">First</button>
                  </li>

                  <li v-for="page in paginationPages" :key="page" class="page-item" :class="{ 'disabled': page === '...', 'active': page === pagination.currentPage }">
                    <button class="page-link" @click="setPage(page)">{{ page }}</button>
                  </li>

                  <li class="page-item" :class="{ 'disabled': pagination.currentPage === pagination.lastPage}">
                    <button class="page-link" @click="setPage(pagination.lastPage)">Last</button>
                  </li>
                </ul>
              </nav>
            </div>
            <div class="col-4 pb-2">
              <div class="input-group input-group-sm">
                <div class="input-group-prepend">
                  <form id="search-form">
                    <input type="text" name="auditorSearch" id="auditorSearch" class="form-control form-control-sm" v-model="filters.query" placeholder="Search Auditor">
                  </form>
                  <label class="input-group-text input-group-sm bg-white small border-0" for="rowCount"># of Row(s):</label>
                </div>
                <select class="form-control form-control-sm rounded-left" id="rowCount" v-model="filters.count">
                  <option value="30">30</option>
                  <option value="50">50</option>
                  <option value="100">100</option>
                  <option value="10000">All</option>
                </select>
              </div>
            </div>
          </div>

          <div class="row no-gutters justify-content-between mb-1">
            <div class="col-sm-12">
              <table class="small e-tbl text-center">
                <thead class="bg-white" style="position: sticky; top: 0; z-index: 2">
                <tr>
                  <th class="align-middle" rowspan="2" style="width: 1em" scope="col">#</th>
                  <th class="align-middle" rowspan="2" scope="col">NAME</th>
                  <th class="align-middle" rowspan="2" scope="col">MANAGEMENT OFFICE</th>
                  <th class="align-middle" rowspan="2" scope="col">DEPARTMENT</th>
                  <th class="align-middle" rowspan="2" scope="col">POSITION</th>
                  <th class="align-middle" colspan="4" scope="col">LAST AUDIT</th>
                  <th class="align-middle" rowspan="2" scope="col">CERTIFICATE EXPIRY</th>
                  <th class="align-middle" rowspan="2" scope="col">ACTION</th>
                </tr>
                <tr>
                  <th>TYPE</th>
                  <th>PORT / OFFICE ADDRESS</th>
                  <th>COUNTRY</th>
                  <th>DATE</th>
                </tr>
                </thead>
                <tbody>
                <tr v-if="loading">
                  <td colspan="100%">
                    <loading></loading>
                  </td>
                </tr>
                <tr v-else v-for="(auditor, index) in auditorList" class="ia-auditor-list no-select" @dblclick="showAuditor(auditor.id)">
                  <td>{{ pagination.from + index}}</td>
                  <td>{{ auditor?.name?.toUpperCase() }}</td>
                  <td>{{ auditor?.management_office?.name.toUpperCase() }}</td>
                  <td>{{ auditor?.department?.name.toUpperCase() }}</td>
                  <td>{{ auditor?.position?.name.toUpperCase() }}</td>
                  <td>Audit</td>
                  <td>{{ auditor?.latest_audit?.auditorable?.port?.name }}</td>
                  <td>{{ auditor?.latest_audit?.auditorable?.country?.name }}</td>
                  <td>{{ auditor?.latest_audit?.auditorable?.audit_date_end?.human }}</td>
                  <td>{{ auditor?.certificate?.expiration_date }}</td>
                  <td>
                    <div class="dropdown dropright my-1">
                      <button
                        id="actionDropdown"
                        aria-expanded="false"
                        aria-haspopup="true"
                        class="btn btn-sm btn-secondary force-white-all"
                        data-toggle="dropdown"
                      >
                        <font-awesome-icon icon="cogs"/>
                      </button>
                      <div aria-labelledby="actionDropdown" class="dropdown-menu py-0">
                        <button class="dropdown-item text-secondary font-weight-bolder"
                                @click="showAuditor(auditor.id)"
                                v-if="isAllowed('audit-team.auditors.view')"
                        >
                          <font-awesome-icon icon="eye"/>
                          View
                        </button>
                        <button class="dropdown-item text-secondary font-weight-bolder"
                                @click="showAuditorNewTab(auditor.id)"
                                v-if="isAllowed('audit-team.auditors.view')"
                        >
                          <font-awesome-icon icon="share"/>
                          View on New Tab
                        </button>
                        <button class="dropdown-item text-secondary font-weight-bolder text-danger"
                                @click="deleteSelected(auditor.id)"
                                v-if="isAllowed('audit-team.auditors.delete')"
                        >
                          <font-awesome-icon icon="trash" class="force-danger-all"/>
                          Delete
                        </button>
                      </div>
                    </div>
                  </td>
                </tr>
                </tbody>
              </table>
            </div>
          </div>
          <AddAuditor
          />
        </div>
      </div>
    </div>
  </div>



</template>

<style scoped>
.e-tbl  thead tr th {
  border: 1px solid #b1b1b1;
}
</style>
